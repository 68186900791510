<template>
  <div class="user-testimonial">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-w-full tw-h-full tw-py-10 md:tw-py-20 tw-px-10 md:tw-px-32 tw-bg-white tw-text-primary"
    >
      <h1
        class="tw-font-semibold tw-text-2xl md:tw-text-3xl tw-pb-4 tw-text-primary tw-text-center tw-m-0"
      >
        Hear how our customers use Scenera MAIstro
      </h1>
      <!-- Need to change the description with actual data or change to a new copy -->
      <p class="tw-ext-lg md:tw-text-xl tw-text-primary tw-text-center tw-m-0">
        Learn how Scenera MAIstro empowers hundreds of teams.
      </p>
      <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 md:tw-gap-16 lg:tw-pt-10">
        <UserCard
          v-for="(review, index) in testimonials"
          :key="index"
          :name="review.name"
          :title="review.title"
          :description="review.description"
          :avatar="review.avatar"
          :company-logo="review.companyLogo"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import UserCard from './UserCard.vue';
const testimonials = [
  {
    name: 'Eita Yanagisawa',
    title: 'Sr. GM of System Solutions Business Division, Sony Semiconductor Solutions Corporation',
    description:
      '“Scenera’s leading AI Topology data management service, MAIstro, utilized by Sony’s AITRIOS will enable smart building security and safety monitoring capabilities. The offering from our partnership solves many pain points and burdens of CRE customers in AI analytics applications, enabling complete utilization of edge-to-cloud computing infrastructures, as a perfect pair to AITRIOS’ efforts in evolving its partner program.”',
    avatar: '/eita.jpeg',
    companyLogo: '/sony.png',
  },
  {
    name: 'Matt Tyler',
    title: 'VP of strategic innovation and business development, Wachter',
    description:
      '"Using a product such as Scenera MAIstro, which sets up multiple cameras in strategic locations throughout an asset, allows property owners to reduce their human security workforce and deploy those workers in different ways, such as walking the property and interacting with persons in the building rather than simply staring all the time at video monitors."',
    avatar: '/male-circle.svg',
    companyLogo: '/wachter_logo.svg',
  },
];
</script>
<style></style>
