<template>
  <div class="partner tw-bg-mainGray">
    <h1
      class="tw-w-full tw-text-center tw-text-3xl tw-font-semibold md:tw-mb-4 tw-text-primary tw-m-0"
    >
      Trusted by leading companies in multiple industries
    </h1>
    <!-- Need to use two sets of company logo to make the infinite carousel -->
    <div
      class="tw-bg-mainGray tw-p-8 tw-w-full tw-inline-flex tw-flex-nowrap tw-overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
    >
      <ul
        class="tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-animate-infinite-scroll tw-list-none"
      >
        <template v-for="(logo, index) in logos" :key="index">
          <li class="tw-mx-8">
            <img class="tw-h-12 tw-min-w-28" :src="logo.src" :alt="logo.alt" />
          </li>
        </template>
      </ul>
      <ul
        class="tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-animate-infinite-scroll tw-list-none"
      >
        <template v-for="(logo, index) in logos" :key="index">
          <li class="tw-mx-8">
            <img class="tw-h-12 tw-min-w-28" :src="logo.src" :alt="logo.alt" />
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
const logos = [
  { src: '/cxc_logo.svg', alt: 'CXC' },
  { src: '/gs25.svg', alt: 'GS25' },
  { src: '/lg_logo.svg', alt: 'LG' },
  { src: '/microsoft.svg', alt: 'Microsoft' },
  { src: '/sni_logo.svg', alt: 'S&I' },
  { src: '/sony_logo.svg', alt: 'Sony' },
  { src: '/wachter_logo.svg', alt: 'Wachter' },
  { src: '/wistron_logo.svg', alt: 'Wistron' },
  { src: '/blume_logo.svg', alt: 'Team Blume' },
];
</script>
<style lang=""></style>
