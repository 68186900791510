<template>
  <div class="use-cases">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-w-full tw-gap-6 tw-p-4 md:tw-p-8 tw-bg-mainGray"
    >
      <h1
        class="tw-font-semibold tw-text-2xl md:tw-text-3xl tw-w-full md:w-1/2 tw-text-center tw-text-primary tw-m-0"
      >
        See how we revolutionize different industries
      </h1>
      <div class="tw-flex tw-flex-row tw-justify-end tw-w-full tw-pr-16">
        <a href="/newsroom" class="tw-cursor-pointer tw-underline tw-text-primary tw-m-0"
          >Explore All Success Stories ></a
        >
      </div>
      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 md:tw-gap-10 tw-px-8 tw-pb-8"
      >
        <UseCaseCard
          v-for="(useCase, index) in useCasesInfo"
          :key="index"
          :category="useCase.category"
          :image="useCase.image"
          :description="useCase.description"
          :is-sustainable="useCase.isSustainable"
          :url="useCase.url"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import UseCaseCard from './UseCaseCard.vue';
const useCasesInfo = [
  {
    category: 'Technology',
    image: '/Azure.webp',
    description: `Scenera Streamlines Smart Camera & Cloud App Integration with Microsoft Azure`,
    isSustainable: false,
    url: '/scenera-sets-the-new-standard-for-distributed-ai-over-5g-powered-by-microsoft-azure/',
  },
  {
    category: 'Building Management',
    image: '/S&I.jpg',
    description: `S&I Building Management Application Uses Scenera MAIstro`,
    isSustainable: false,
    url: '/si-building-management-application/',
  },
  {
    category: 'Convenience Store',
    image: '/gs25.jpg',
    description: `Scenera Improves GS25 Retail Operations `,
    isSustainable: true,
    url: '/gs25-retail-success-story/',
  },
];
</script>
<style lang=""></style>
