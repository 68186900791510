<template>
  <div class="introduction">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-py-14 tw-bg-mainGray lg:tw-gap-4 tw-px-10 lg:tw-px-32"
    >
      <h1 class="tw-text-2xl md:tw-text-3xl tw-text-primary tw-font-semibold tw-text-center tw-m-0">
        Facility Management with AI-Driven Data Solutions
      </h1>
      <div
        class="tw-flex tw-flex-col lg:tw-flex-row tw-pt-8 lg:tw-pt-4 tw-gap-6 lg:tw-gap-14 tw-justify-center tw-items-center"
      >
        <div
          class="video-introduction tw-h-[200px] md:tw-h-[250px] lg:tw-h-[300px] tw-w-full lg:tw-w-1/2 tw-flex tw-flex-col tw-justify-center tw-items-center"
        >
          <iframe
            src="https://www.youtube.com/embed/WDubqZNL-XU?autohide=1&autoplay=1"
            wmode="transparent"
            type="video/mp4"
            width="70%"
            height="100%"
            allow="encrypted-media; picture-in-picture"
            allowfullscreen
            title="Scenera MAIstro"
            class="tw-rounded-2xl"
          />
        </div>
        <div
          class="introduction-content tw-w-full lg:tw-w-1/2 tw-flex tw-flex-col tw-justify-center tw-px-6 md:tw-p-10"
        >
          <p class="tw-text-primary tw-m-0">
            <b class="tw-font-bold">Scenera MAIstro</b> is a leading cloud-based AIoT service for
            facility management that turns real-time data from cameras, sensors, and monitors into
            actionable insights and smart building scenes. Each scene, triggered by key actions,
            combines short videos with relevant data through our AI-driven process. Leveraging
            advanced technologies like Edge AI and Copilot, our platform ensures efficient data
            management, proactive problem-solving, cost savings, and improved operational
            efficiency. Discover the future of intelligent facility management with Scenera MAIstro
            today.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style></style>
