<template>
  <div class="hero">
    <div class="carousel tw-flex tw-justify-center tw-pt-[72px] md:tw-pt-[82px]">
      <!-- Adjusted the height of the hero section to be the total vh minus the height of the fixed nav bar -->
      <div
        class="carousel-inner tw-relative tw-w-full tw-h-[calc(100vh-82px)] tw-overflow-hidden tw-bg-white"
      >
        <!-- Control the transition and the content of the slides -->
        <CarouselItem
          v-for="(slide, index) in slidesInfo"
          :key="index"
          :slide="slide"
          :current-slide="currentSlide"
          :index="index"
        />
        <!-- Control the arrow buttons on the side -->
        <CarouselControls :next-slide="nextSlide" :prev-slide="prevSlide" />
        <!-- Control the pagination of the slides -->
        <CarouselPagination
          :current-slide="currentSlide"
          :slide-count="slideCount"
          :change-slide="changeSlide"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import CarouselItem from './carousel/CarouselItem.vue';
import CarouselControls from './carousel/CarouselControls.vue';
import CarouselPagination from './carousel/CarouselPagination.vue';
// The index of the current slide. Start from 0.
const currentSlide = ref<number>(0);

// The number of the slides
const slideCount = ref<number>(0);

// The ID of the current slide interval (Store it here, so we can clear the interval when needed)
const slideInterval = ref<null | number>(null);

// The headline, description, and the image path of the slides
const slidesInfo = [
  {
    headline: 'Transforming Data Into Actionable Insights',
    description:
      'Discover how Scenera MAIstro streamlines and improves facility management with seamless edge-to-cloud data processing and smart AI orchestration to optimize building operations.',
    image: '/hero_main.jpg',
  },
  {
    headline: 'Organized Video and IoT Data by Operational Themes',
    description:
      'Efficiently collect and filter data using edge AI to reflect your unique operational requirements. Organize data by categories for improved decision-making.',
    image: '/hero_iot.png',
  },
  {
    headline: 'Customer-Driven Data Events',
    description:
      'Engage with data through tailored interactions and build relevant big data outputs focused on the events and information that matter to you.',
    image: '/hero_customer_driven.png',
  },
  {
    headline: 'Turn your facilities data into actions',
    description:
      'Tailored AI that meets Facilities Staff where they are, to improve operations, efficiency, and tenant retention.',
    image: '/hero_privacy.jpg',
  },
  {
    headline: 'Stay Informed with Copilot',
    description:
      'Empower your facility management and even your tenants with a people-first dashboard that provides transparency and insight across relevant operations.',
    image: '/hero_copilot.jpg',
  },
];

// Set 3-second interval to enable automatic slide changing
const incrementSlideIndex = () => {
  slideInterval.value = setInterval(() => {
    currentSlide.value = currentSlide.value < slidesInfo.length - 1 ? currentSlide.value + 1 : 0;
  }, 10000);
};

// Clear the current Slide inteval and reset the 3-second interval for changing slides
const resetInteval = () => {
  if (slideInterval.value !== null) {
    clearInterval(slideInterval.value);
    incrementSlideIndex();
  }
};

// Change the current slide to the slide with +1 index. Go back to the first one if it is already the last index
const nextSlide = () => {
  currentSlide.value = currentSlide.value < slidesInfo.length - 1 ? currentSlide.value + 1 : 0;
  resetInteval();
};

// Change the current slide to the slide with -1 index. Go to the last index if it is already the first index
const prevSlide = () => {
  currentSlide.value = currentSlide.value > 0 ? currentSlide.value - 1 : slidesInfo.length - 1;
  resetInteval();
};

// Change the slide to the slide with specific index
const changeSlide = (index: number) => {
  currentSlide.value = index;
  resetInteval();
};

// Initiate interval for auto-change of slides when the component is mounted
onMounted(() => {
  incrementSlideIndex();
  slideCount.value = slidesInfo.length;
});

// Cancel the interval for changing slides when the component is unmounted
onUnmounted(() => {
  if (slideInterval.value !== null) {
    clearInterval(slideInterval.value);
  }
});
</script>
<style lang=""></style>
